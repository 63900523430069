import { Navbar } from "react-bootstrap";
import { useHistory, } from "react-router";
import { Link } from "react-router-dom";
import logo from '../assets/apple.png'

function NavBar(){

    const history=useHistory()

    return  (
    <Navbar className=" navbar  navbar-light bg-light"  expand="md" >
        <div className="container ">
            <a className="navbar-brand" href="#">
                <img src={logo} alt="Apple" width="30" height="24" className="d-inline-block align-text-top"></img>
                Mosterei Kiefer
            </a>
        
            {/*<Navbar.Toggle aria-controls="responsive-navbar-nav"   />*/}
            <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end " >

                <ul className="navbar-nav">
                    <li className="nav-item">
                    <a className="nav-link " type="button" onClick={()=>history.push("/")} >                 
                        Home                        
                        </a>
                    </li>
                   
                
                </ul>           
                
            </Navbar.Collapse>
        </div>
    </Navbar>
    )
}

export default NavBar;