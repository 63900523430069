import React, { Component } from "react";
import { BrowserRouter, Router, Switch, Route, Redirect,useParams, useHistory } from "react-router-dom";

import NoMatch from "../views/NoMatch";
import Home from "../views/Home";
import {Navbar, Nav, Button} from "react-bootstrap"
import DataProtection from "../views/DataProtection";
import Impressum from "../views/Impressum"
import NavBar from "../components/navbar";
import BagInBox from "../views/BagInBox";

/**
 * routing
 */
function RoutesApp() {      
    
    const history = useHistory();

    return (
        
        <div className="">
            <BrowserRouter>
           
            <NavBar></NavBar>
            
            <Switch >
              
                <Route path="/" exact component={Home} />  
                <Route path="/impressum" exact component={Impressum} />               
                <Route path="/datenschutz" exact component={DataProtection} />    
                <Route path="/bag-in-box" exact component={BagInBox}   />          
                <Route component={NoMatch} />
               
            </Switch>
            </BrowserRouter>
           
    </div>
    


    );

}
export default RoutesApp;
