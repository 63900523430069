

import  RoutesApp from './routes/routes';
import {Helmet} from 'react-helmet'


function App() {
  return (
    <div  >
      <Helmet>
        <title>Mosterei Kiefer: Frisch gepresster Apfelsaft</title>
        <meta name="description" content="Mosterei Kiefer: ..."  />
      </Helmet>
      <RoutesApp ></RoutesApp>

    </div>
  );
}

export default App;
