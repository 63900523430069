
import { Card } from "react-bootstrap"
import Apple from "../assets/appleBackground.webp"
import Footer from "../components/footer";
import { Button } from "react-bootstrap";

import {MdEmail,MdPhone,MdMap} from "react-icons/md"
import { useEffect } from "react";
import { useLocation } from "react-router";
import Slider from "react-slick";

import colors from "../style/theme.module.scss"

import BoxSquare from "../assets/box_square.webp"
import TrottenSquare from "../assets/trotten_square.webp"
import AppleSquare from "../assets/apfelsaft.webp"

import ProcessLG from "../assets/process_lg.webp"
import TrotteLG from "../assets/trotte_lg.webp"
import ApfelsaftLG from "../assets/apfelsaft_lg.webp"

import StartImage from "../assets/start_grafik.webp"

function BagInBox(props){

    

    return (
      
        <div className="">    

          <div className="row">
            <div className="col-12" style={{position:"relative"}} >
                               
                <div style={{backgroundImage: `url(${Apple})`,backgroundSize:"cover",backgroundPosition: "center center",height:"200px" }} className="darkenedFlexible" >
                    <div style={{
                    position: 'absolute', 
                    top:"30%",
                    left: 0, 
                    right: 0, 
                    marginLeft: "auto",
                    marginRight: "auto",
                    textAlign: "center"
                    }}>
                        <h1 className="text-white " style={{fontSize:"50px"}} >Bag-In-Box</h1>
                                
                    </div>
                
                </div>

                
               
            </div>
        </div>      

           
            <div className="container pt-5 pb-5">
                
                <div className="row ">
                    
                    <div className="col-12 mb-2" id="unsereLeistung">
                        <h3 className="text-center mb-0  pb-0 text-dark text-uppercase">Die Alternative zur Flasche</h3>
                        <div className="w-100 d-flex justify-content-center">
                        <div className="text-center mb-0 mt-1 pb-0" style={{width:"100px", color: colors.primary,backgroundColor: colors.primary, height: 3 }}>
                        </div>
                        </div>
                    </div>
                   
                    
                    <div className="col-12 row mb-2 ">                      
                        <div className="text-center">
                            <p className="mb-0">Bag-In-Box bedeutet "Beutel im Karton" oder einfach "Saftkiste".
                            </p>
                            <p className="mb-0">Ein steriler, doppelwandiger Beutel aus PE-Folie mit tropfischerem Ausgusshahn (Bag) wird in einen Karton (Box) gesteckt.</p>
                            <div className="d-md-flex justify-content-center  text-center">
                            <p className="mb-0 ">Sie haben die Wahl zwischen</p>
                            <p className="fw-bold mb-0">&nbsp;5 und 10 Liter&nbsp;</p> 
                            <p className="mb-0">Bag-In-Box. </p>
                            </div>
                        </div>
                       
                        
                    </div>
                    
                </div>
                <div className="row mt-4 mb-2">
                    <div className="col-12 d-flex justify-content-center">    
                        <img src={BoxSquare} width="200px" style={{borderRadius: "10%"}}></img>
                    </div>        
                           
                </div>
            </div>
                
      
            <div className="container-fluid pt-5 pb-5 bg-light">
                <div className="row ">
                    
                    <div className="col-12 mb-2" id="Handhabung">
                        <h3 className="text-center mb-0  pb-0 text-dark text-uppercase">Handhabung</h3>
                        <div className="w-100 d-flex justify-content-center">
                        <div className="text-center mb-0 mt-1 pb-0" style={{width:"100px", color: colors.primary,backgroundColor: colors.primary, height: 3 }}>
                        </div>
                        </div>
                    </div>

                </div> 
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <p className="mb-0 text-center">
                                Ungeöffnet ist die "Saftkiste" mindestens 1 Jahr haltbar.
                                                    </p>
                            <p className="mb-0 text-center">
                                Beim Zapfen ziet sich der Beutel zusammen, es tritt keine Luft in den Beutel ein. Dadurch ist das geöffnete Gebinde mindestens 2 Monate ungekühlt haltbar.
       
                            </p>
                        </div>
                    </div>
                </div>
            </div>   

            <div className="container-fluid pt-5 pb-5 bg-white">
                <div className="row ">
                    
                    <div className="col-12 mb-2" id="Verpackung">
                        <h3 className="text-center mb-0  pb-0 text-dark text-uppercase">Verpackung</h3>
                        <div className="w-100 d-flex justify-content-center">
                        <div className="text-center mb-0 mt-1 pb-0" style={{width:"100px", color: colors.primary,backgroundColor: colors.primary, height: 3 }}>
                        </div>
                        </div>
                    </div>

                </div> 
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <p className="mb-0 text-center">
                                Der PE-Beutel wird nur einmal verwendet und kann dann dem Recycling (gelber Sack) zugeführt werden.
                                                    </p>
                            <p className="mb-0 text-center">
                               Der Karton ist mehrmals verwendbar, beim nächsten Füllen muss nur der Beutel erneuert werden.
                            </p>
                        </div>
                    </div>
                </div>
            </div>     

             <div className="container-fluid pt-5 pb-5 bg-light">
                <div className="row ">
                    
                    <div className="col-12 mb-2" id="Vorteile">
                        <h3 className="text-center mb-0  pb-0 text-dark text-uppercase">Vorteile</h3>
                        <div className="w-100 d-flex justify-content-center">
                        <div className="text-center mb-0 mt-1 pb-0" style={{width:"100px", color: colors.primary,backgroundColor: colors.primary, height: 3 }}>
                        </div>
                        </div>
                    </div>

                </div> 
                <div className="container">
                    <div className="row">
                        
                        <div className="d-flex mx-2 flex-column h-100 justify-content-center align-items-md-ceenter align-items-center ">
                            <h6 className="text-left">Geringer Platzbedarf / geringes Trasnportgewicht</h6>
                            <ul>
                            <li>
                                <p className="text-left mb-0">
                                5 Liter Box: 25 cm x 16,5cm x 6cm = 5,22kg     
                                </p>
                            </li>
                            <li>
                                <p className="text-left mb-0">
                                10 Liter Box: 31 cm x 19,5cm x 19,5cm = 10,4kg
                                </p>
                            </li>
                            <li>
                                <p className="text-left mb-0 text-muted">
                                Zum Vergleich: 10 l Saft in Flaschen mit Kasten = 17,5kg
                                </p>
                            </li>                          

                        </ul>


                        </div>
                        <div className="d-flex mx-2 flex-column h-100 justify-content-center align-items-md-ceenter align-items-center ">
                           
                            <ul>
                            <li>
                                <p className="text-left mb-0">
                                Hervorragende Saftqualität, da mit niedriger Safttemperatur abgefüllt werden kann.
                                </p>
                            </li>
                            <li>
                                <p className="text-left mb-0">
                                Durch die Aufbewahrung im Karton ist der Saft lichtgeschützt, so dass lichtempfindliche Vitamine nicht verloren gehen.
                                </p>
                            </li>
                            <li>
                                <p className="text-left mb-0">
                                Die Bag-In_box kann geöffnet in Küche oder Wohnraum gelagert werden, ansprechendes, praktisches Design.
                                </p>
                            </li>    
                            <ul>
                            <li>
                                <p className="text-left mb-0 text-muted">
                                Hinweis: Aufrecht lagern. Nach Anbruch flach legen und nicht mehr aufstellen.
                                </p>
                            </li>  
                            </ul>  
                            <li>
                                <p className="text-left mb-0 ">
                                Der 5l-Bag passt im Sommer optimal in den Kühlschrank.
                                </p>
                            </li>                       

                        </ul>


                        </div>
                    </div>
                </div>
            </div>      
               


            <Footer></Footer>
        </div>

    )

}

export default BagInBox;